
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.movers-popup,
[class*='movers-popup--'] {
  max-height: 85vh;
  padding: $spacing * 3 $spacing * 2;
  background: $white;

  &.generic-popup {
    display: block;
    overflow: auto;
    max-width: 72rem;
  }

  ::v-deep {
    .eligibility,
    .eligibility__inner {
      padding: 0 !important;
    }
  }

  @include mq(m) {
    padding: $spacing * 3.5 $spacing * 3.5;
  }
}
